.form-input-wrapper {
  .form-input-label {
    margin-bottom: 8px;
    margin-left: 2px;
    font-size: 14px;
    font-weight: 500;
  }

  input.form-input {
    height: 36px;
    width: 280px;
    margin-right: 24px;
    padding: 6px 8px;
    font-size: 14px;
    font-family: Montserrat;
    border-radius: 6px;
    border: 1px solid var(--SoothingBreeze);

    &::placeholder {
      color: var(--SoothingBreeze);
      font-family: Montserrat;
      font-weight: 300;
    }

    &:focus {
      border: none;
      outline: none;
      box-shadow: 0 0 0 2px var(--ElectronBlue);
    }
  }

  textarea.form-input {
    height: 220px;
    width: 100%;
    padding: 12px 16px;
    font-size: 16px;
    font-family: Montserrat;
    border-radius: 6px;
    border: 1px solid var(--SoothingBreeze);
    resize: none;

    &::placeholder {
      color: var(--SoothingBreeze);
      font-family: Montserrat;
      font-weight: 300;
    }
  }
}

@media screen and (max-width: 680px) {
  .form-input-wrapper {
    input.form-input {
      height: 36px;
      width: 100%;
    }

    textarea.form-input {
      height: 260px;
    }
  }
}
