.privacy-policy {
  padding-top: 80px;
  min-height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow-y: scroll;

  .content-wrapper {
    flex: 1;
    width: 100%;
    padding: 40px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: white;

    .content {
      width: 72.5%;
    }
  }
}

@media screen and (max-width: 680px) {
  .privacy-policy {
    width: 100vw;
    font-size: 12px;

    .content-wrapper {
      padding: 0px 0;

      .content {
        width: 90%;
      }
    }
  }
}
