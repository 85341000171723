.header {
  z-index: 10;
  position: fixed;
  top: 0;
  left: 0;
  height: 80px;
  width: 100%;
  padding: 0px 44px;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: var(--ElectronBlue);

  .header-button {
    width: 22px;
    height: 22px;
    position: absolute;
  }

  .home-button {
    left: 20px;
    cursor: pointer;
  }

  .search-button {
    right: 20px;
    cursor: pointer;
  }

  .search-button-wrapper {
    background-color: var(--DraculaOrchid);;
    border: none;
    color: white;
    padding: 10px 20px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    margin: 4px 2px;
    cursor: pointer;
    border-radius: 16px;
  }

  .search-button-wrapper:hover {
    opacity: 0.9;
  }

  .search-icon {
    position: absolute;
    stroke: var(--DraculaOrchid);
    fill: none;
    stroke-width: 2.5px;
  }

  .logo {
    width: 320px;
    cursor: pointer;

    &:hover {
      opacity: 0.9;
    }
    &:active {
      opacity: 0.8;
    }
  }
}

/* On screens that are 600px or less, set the background color to olive */
@media screen and (max-width: 680px) {
  .header {
    height: 120px;
    width: 100vw;
    padding: 0px;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;

    &.no-search {
      height: 80px;
      flex-direction: row;
    }
  }
}
