.footer {
  flex-shrink: 0;
  height: 56px;
  width: 100%;
  padding: 0px 32px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: white;
  background-color: var(--DraculaOrchid);
  font-weight: 300;

  .links {
    display: flex;
    align-items: center;
    font-weight: 500;

    .link {
      color: white;
      text-decoration: none;
      cursor: pointer;
      user-select: none;

      &:not(:first-child) {
        margin-left: 32px;
      }

      &:hover {
        opacity: 0.9;
      }
      &:active {
        opacity: 0.7;
      }
    }
  }
}

@media screen and (max-width: 680px) {
  .footer {
    height: 40px;
    padding: 0px 5%;
    font-size: 10px;

    .links {
      .link:not(:first-child) {
        margin-left: 16px;
      }
    }
  }
}
