.banner {
  height: 40px;
  display: flex;
  justify-content: center;
  background-color: #f8f1ae;
  box-shadow: 2px 2px 2px black;
  z-index: 0;

  .info-icon {
    position: absolute;
    transform: translate(0%, 60%);
    left: 0px;
    height: 18px;
    width: 18px;
    margin: auto 10px;
    stroke-width: 2px;
    stroke-linecap: round;
    stroke-linejoin: round;
  }

  .delete-icon {
    margin: auto;
    position: absolute;
    right: 20px;
    transform: translate(50%, 50%);
    height: 20px;
    width: 20px;
    stroke-width: 2px;
    stroke-linecap: round;
    stroke-linejoin: round;
    cursor: pointer;
  }
  .marquee {

    position: relative;
    margin: 0 35px;
    width: 100vw;
    max-width: 100%;
    overflow-x: hidden;

    .banner-track {
      position: absolute;
      min-width: 100%;
      margin-top: 10px;
      white-space: nowrap;
      will-change: transform;
      animation: marquee 9s linear infinite;
      transform-box: content-box;
    }
  }
}

@keyframes marquee {
  from { transform: translateX(100vw); }
  to { transform: translateX(-100%); }
}

@media screen and (min-width: 920px) {
  .banner {
    .marquee {
      margin: 0 65px;

      .banner-track {
        animation: marquee 15s linear infinite;
        animation-delay: -4s;
      }
    }

    .info-icon {
      left: 30px;
    }

    .delete-icon {
      right: 50px;
    }
  }
}