.agent-details {
  min-height: 100%;
  width: 100%;
  padding-top: 80px;
  display: flex;
  flex-direction: column;
  align-items: center;

  .hero {
    flex-shrink: 0;
    height: 200px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: white;
    background: linear-gradient(rgba(116, 185, 255, 0.6), rgba(116, 185, 255, 0.6)), url("../../assets/bricks.png");
    background-size: cover;
    background-position: center center;
    font-size: 36px;
    font-weight: 700;

    .cta-row {
      display: flex;
      justify-content: center;
      align-items: center;

      .cta-button {
        height: 48px;
        padding: 12px 16px;
        min-width: 200px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        color: white;
        background-color: var(--DraculaOrchid);
        font-size: 24px;
        text-decoration: none;
        cursor: pointer;
        user-select: none;

        &:not(:first-child) {
          margin-left: 16px;
        }

        &:hover {
          opacity: 0.9;
        }
        &:active {
          opacity: 0.7;
        }
      }
    }
  }

  .content-wrapper {
    flex: 1;
    width: 72.5%;
    padding: 40px 0;

    .agent-info {
      margin-bottom: 60px;
      display: flex;
      align-items: center;

      .profile-image-placeholder {
        height: 232px;
        width: 232px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        background-color: var(--SoothingBreeze);

        .user-icon {
          height: 160px;
          width: 160px;
          stroke: white;
          fill: none;
          stroke-width: 1.5px;
          stroke-linecap: round;
          stroke-linejoin: round;
        }
      }

      .agent-info-wrapper {
        flex: 1;
        height: 100%;
        padding: 12px 40px;
        padding-right: 0px;
        display: flex;
        flex-direction: column;

        .info-row {
          flex-grow: 0;
          display: flex;
          justify-content: space-between;
          // align-items: center;

          .info-wrapper {
            .name {
              font-size: 28px;
              font-weight: 500;
              margin-bottom: 6px;
            }

            .rating-wrapper {
              margin-bottom: 2px;

              .star,
              .half-star {
                height: 52px;
                width: 52px;
                margin-left: 6px;
                padding-top: 3px;
                stroke: var(--BrightYarrow);
                fill: var(--BrightYarrow);
                stroke-width: 2px;
                stroke-linecap: round;
                stroke-linejoin: round;
                transform: scale(1, 1);
                transform-origin: 50% 50%;
              }

              .half-star {
                transform: scale(-1, 1);
                transform-origin: 50% 50%;
              }
            }

            .rest {
              display: flex;
              flex-direction: column;
              flex: 1;

              .state {
                font-size: 18px;

                .pin-icon {
                  height: 16px;
                  width: 16px;
                  margin-right: 8px;
                  stroke: var(--AmericanRiver);
                  fill: none;
                  stroke-width: 2px;
                  stroke-linecap: round;
                  stroke-linejoin: round;
                }
              }

              .review {
                flex: 1;
                font-size: 18px;
                font-style: italic;
              }
            }
          }
        }
      }

      .rate-agent-wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;

        .rate-agent-title {
          margin-bottom: 12px;
        }
      }

      .cta-button {
        padding: 12px 16px;
        min-width: 200px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        color: white;
        background-color: var(--DraculaOrchid);
        font-size: 24px;
        font-weight: 700;
        text-decoration: none;
        cursor: pointer;
        user-select: none;

        &:not(:first-child) {
          margin-left: 16px;
        }

        &:hover {
          opacity: 0.9;
        }
        &:active {
          opacity: 0.7;
        }
      }
    }

    .content-title {
      width: 72.5%;
      margin-bottom: 40px;
      font-size: 22px;
      font-weight: 600;
    }

    .reviews-wrapper {
      .review-wrapper {
        width: 100%;
        margin-bottom: 40px;
        padding: 20px 34px;
        display: flex;
        flex-direction: column;
        font-size: 20px;
        font-style: italic;
        background-color: var(--SuperLightGray);
        border-radius: 12px;

        &:last-child {
          margin-bottom: 0px;
        }

        .rating-wrapper {
          margin-bottom: 16px;
          margin-left: -8px;
          display: flex;
          justify-content: space-between;
        }
      }
    }
  }

  .loader {
    color: var(--SuperLightGray);
  }
}

@media screen and (max-width: 680px) {
  .agent-details {
    width: 100vw;
    display: flex;
    flex-direction: column;
    overflow-x: hidden;

    .hero {
      font-size: 22px;
      text-align: center;

      .hero-title {
        margin-bottom: 20px;
      }

      .cta-row .cta-button {
        height: 36px;
        min-width: 0px;
        font-size: 16px;
      }
    }

    .content-wrapper {
      width: 90%;
      padding: 20px 0;

      .agent-info {
        margin-bottom: 20px;
        display: flex;
        align-items: flex-start;
        flex-wrap: wrap;
        justify-content: center;

        .profile-image-placeholder {
          height: 64px;
          width: 64px;

          .user-icon {
            height: 44px;
            width: 44px;
          }
        }

        .cta-button {
          font-size: 16px;
          margin-left: 0px !important;
        }

        .agent-info-wrapper {
          flex: 1;
          height: 100%;
          padding: 4px 20px 4px 20px;
          display: flex;
          flex-direction: column;

          .name-row {
            flex-grow: 0;
            display: flex;
            justify-content: space-between;
            align-items: center;

            .name {
              font-size: 20px;
              font-weight: 500;
            }

            .rating-wrapper {
              z-index: 1;
              flex-shrink: 0;
              width: auto;
              margin-left: 6px;

              .star,
              .half-star {
                height: 18px;
                width: 18px;
                margin-left: 2px;
              }
            }
          }

          .rest {
            display: flex;
            flex-direction: column;
            flex: 1;

            .state {
              margin-bottom: 12px;
              font-size: 12px;

              .pin-icon {
                height: 12px;
                width: 12px;
                margin-right: 4px;
              }
            }

            .review {
              flex: 1;
              font-size: 12px;
              font-style: italic;
            }
          }
        }
      }

      .content-title {
        width: 90%;
        margin-bottom: 20px;
      }

      .reviews-wrapper {
        .review-wrapper {
          margin-bottom: 20px;
          padding: 12px 16px;
          font-size: 12px;

          &:last-child {
            margin-bottom: 0px;
          }

          .rating-wrapper {
            margin-bottom: 8px;
            margin-left: -8px;
          }
        }
      }
    }
  }
}
