.search {
  padding-top: 80px;
  min-height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;

  .hero {
    flex-shrink: 0;
    height: 200px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: white;
    // background-color: var(--GreenDarnerTail);
    background: linear-gradient(rgba(116, 185, 255, 0.6), rgba(116, 185, 255, 0.6)), url("../../assets/bricks.png");
    background-size: cover;
    background-position: center center;
    font-size: 36px;
    font-weight: 700;

    .hero-title {
      margin-bottom: 20px;
    }

    .cta-row {
      display: flex;
      justify-content: center;
      align-items: center;

      .search-input-wrapper {
        width: 50vw;
        min-width: 300px;
        max-width: 600px;
        margin-left: 0;
      }
    }
  }

  .cta-row {
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 700;

    .cta-button {
      height: 48px;
      padding: 12px 16px;
      min-width: 200px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      color: white;
      background-color: var(--DraculaOrchid);
      font-size: 24px;
      text-decoration: none;
      cursor: pointer;
      user-select: none;

      &:not(:first-child) {
        margin-left: 16px;
      }

      &:hover {
        opacity: 0.9;
      }
      &:active {
        opacity: 0.7;
      }
    }
  }

  .content-wrapper {
    flex: 1;
    width: 100%;
    padding: 40px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: white;

    .content-title {
      width: 72.5%;
      margin-bottom: 40px;
      font-size: 22px;
      font-weight: 600;
    }

    .loader {
      color: var(--SuperLightGray);
    }

    .load-more-button {
      margin-top: 40px;
    }

    .agent-results {
      width: 72.5%;

      .results-zero-state {
        width: 100%;
        font-size: 18px;
        text-align: center;
        opacity: 0.3;
      }
    }
  }
}

@media screen and (max-width: 680px) {
  .search {
    width: 100vw;
    display: flex;
    flex-direction: column;
    overflow-x: hidden;

    .hero {
      font-size: 22px;
      text-align: center;

      .hero-title {
        margin-bottom: 20px;
      }
    }

    .content-wrapper {
      padding: 20px 0;

      .content-title {
        width: 90%;
        margin-bottom: 20px;
      }

      .agent-results {
        width: 90%;
      }

      .load-more-button {
        margin-top: 20px;
      }
    }
  }
}
