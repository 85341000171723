.new-task-modal {
  --modal-height: 85vh;
  --modal-header-height: 110px;
  --modal-bottom-buttons-height: 110px;

  --modal-content-height: calc(var(--modal-height) - var(--modal-header-height) - var(--modal-bottom-buttons-height));

  height: 85vh;
  width: 90vw;
  max-height: 524px;
  max-width: 700px;
  background-color: #fff;

  .modal-contents {
    height: calc(100% - var(--modal-bottom-buttons-height));
    flex: 1;
    flex-grow: 1;
    display: flex;
    flex-direction: column;

    .headline {
      height: var(--modal-header-height);
      width: 100%;
      padding: 40px 50px 30px;
      color: var(--Gray900);
      font-size: 32px;
      font-weight: 700;
      text-align: center;
      letter-spacing: 0.75px;
      // border-bottom: 1px solid #eaeaea;
    }

    .content {
      --title-height: 36px;
      --title-bottom-margin: 20px;

      --details-container-height: calc(100% - var(--title-height) - var(--title-bottom-margin));

      height: var(--modal-content-height);
      padding: 0px 50px 0px;
      overflow-y: scroll;

      .thank-you-text {
        text-align: center;
        font-size: 20px;

        + .thank-you-text {
          margin-top: 12px;
        }
      }

      .info {
        margin-top: 32px;

        > .form-input-wrapper:first-child {
          margin-bottom: 16px;
        }

        .info-row {
          margin-top: 20px;
          display: flex;

          &:first-child {
            margin-top: 0px;
          }
        }
      }
    }
  }

  .bottom-buttons {
    height: var(--modal-bottom-buttons-height);
    margin: 0px 40px;
    padding: 30px 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-top: 1px solid #eaeaea;

    .modal-button {
      align-self: flex-start;
      height: 48px;
      width: 240px;
      padding: 12px 16px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      color: white;
      background-color: var(--ElectronBlue);
      font-size: 24px;
      font-weight: 700;
      text-decoration: none;
      cursor: pointer;
      user-select: none;

      &:not(:first-child) {
        margin-left: 16px;
      }

      &:hover {
        opacity: 0.9;
      }
      &:active {
        opacity: 0.7;
      }

      &.disabled {
        opacity: 0.3;
        cursor: inherit;
      }

      &.cancel {
        color: var(--AmericanRiver);
        background-color: white;
        border: 2px solid var(--AmericanRiver);
      }
    }
  }
}

@media screen and (max-width: 680px) {
  .new-task-modal {
    .modal-contents {
      .content {
        padding: 0px 20px 0px;
        overflow: hidden;

        .thank-you-text {
          font-size: 16px;
        }
      }
    }

    .bottom-buttons {
      height: var(--modal-bottom-buttons-height);
      margin: 0px 20px;
      padding: 30px 0px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-top: 1px solid #eaeaea;

      .modal-button {
        padding: 12px 4px;
        font-size: 13px;
      }
    }
  }
}
