.home {
  min-height: 100vh;
  width: 100%;
  padding-top: 80px;
  display: flex;
  flex-direction: column;

  .hero {
    flex-shrink: 0;
    height: 250px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: white;
    // background-color: var(--GreenDarnerTail);
    background: linear-gradient(rgba(45, 85, 124, 0.8), rgba(45, 85, 124, 0.75)), url("../../assets/hero-backsplash-high-res.jpg");
    background-size: cover;
    background-position: center center;
    font-size: 36px;
    font-weight: 700;
    overflow: visible;

    .hero-title {
      margin-bottom: 30px;
    }
    .subtitle {
      font-size: 16px;
      font-weight: 400;
      margin-bottom: 14px;
    }
  }

  .cta-row {
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 700;

    .cta-button {
      height: 48px;
      padding: 12px 16px;
      min-width: 200px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      color: white;
      background-color: var(--DraculaOrchid);
      font-size: 24px;
      text-decoration: none;
      cursor: pointer;
      user-select: none;

      &:not(:first-child) {
        margin-left: 16px;
      }

      &:hover {
        opacity: 0.9;
      }
      &:active {
        opacity: 0.7;
      }
    }
  }

  .content-wrapper {
    flex: 1;
    width: 100%;
    padding: 40px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: white;

    .content-title {
      width: 72.5%;
      margin-bottom: 40px;
      font-size: 22px;
      font-weight: 600;
    }

    .loader {
      color: var(--SuperLightGray);
    }

    .agent-results {
      width: 72.5%;
    }

    .load-more-button {
      margin-top: 40px;
    }
  }
}

@media screen and (max-width: 480px) {
  .home {
    width: 100vw;
    display: flex;
    flex-direction: column;
    overflow-x: hidden;

    .hero {
      font-size: 22px;
      background: linear-gradient(rgba(45, 85, 124, 0.8), rgba(45, 85, 124, 0.75)), url("../../assets/hero-backsplash-low-res.jpg");
      background-size: cover;
      background-position: center center;
      text-align: center;

      .hero-title {
        margin-bottom: 20px;
      }

      .cta-row .cta-button {
        height: 36px;
        min-width: 0px;
        font-size: 16px;
      }
    }

    .content-wrapper {
      padding: 20px 0;

      .content-title {
        width: 90%;
        margin-bottom: 20px;
        text-align: center;
      }

      .agent-results {
        width: 95%;
      }

      .load-more-button {
        margin-top: 20px;
      }

      .loader {
        margin-top: 10px;
        margin-bottom: 33px;
      }
    }
  }
}
