.add-review {
  padding-top: 80px;
  min-height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;

  .hero {
    flex-shrink: 0;
    height: 200px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: white;
    background: linear-gradient(rgba(116, 185, 255, 0.6), rgba(116, 185, 255, 0.6)), url("../../assets/bricks.png");
    background-size: cover;
    background-position: center center;
    font-size: 36px;
    font-weight: 700;

    .hero-title {
      margin-bottom: 20px;
    }

    .cta-row {
      display: flex;
      justify-content: center;
      align-items: center;

      .cta-button {
        height: 48px;
        padding: 12px 16px;
        min-width: 200px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        color: white;
        background-color: var(--DraculaOrchid);
        font-size: 24px;
        text-decoration: none;
        cursor: pointer;
        user-select: none;

        &:not(:first-child) {
          margin-left: 16px;
        }

        &:hover {
          opacity: 0.9;
        }
        &:active {
          opacity: 0.7;
        }
      }
    }
  }

  .content-wrapper {
    flex: 1;
    width: 100%;
    padding: 40px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: white;

    .content-title {
      width: 72.5%;
      margin-bottom: 32px;
      font-size: 22px;
      font-weight: 600;

      &:not(:first-child) {
        margin-top: 32px;
      }
    }

    .info {
      width: 72.5%;

      > .form-input-wrapper:first-child {
        margin-bottom: 16px;
      }

      .info-row {
        margin-top: 20px;
        display: flex;

        &:first-child {
          margin-top: 0px;
        }

        &.rating {
          display: block;
          .form-input-label {
            margin-bottom: 8px;
            margin-left: 2px;
            font-size: 14px;
            font-weight: 500;
          }

          .rating-input {
            height: 36px;
            width: 280px;
            margin-right: 24px;
            font-size: 14px;
            font-family: Montserrat;
            font-weight: 300;
          }
        }

        .state-select-wrapper {
          display: block;
          .form-input-label {
            margin-bottom: 8px;
            margin-left: 2px;
            font-size: 14px;
            font-weight: 500;
          }

          .state-select {
            height: 36px;
            width: 280px;
            margin-right: 24px;
            font-size: 14px;
            font-family: Montserrat;
            font-weight: 300;
          }
        }
      }
    }

    .existing-agent-link {
      font-size: 14px;
      margin-top: 16px;
      color: var(--Chi-Gong);

      a {
        color: var(--ElectronBlue);
        text-decoration: none;
      }
    }

    .cta-button {
      align-self: flex-start;
      height: 48px;
      width: 200px;
      margin-top: 48px;
      padding: 12px 16px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      color: white;
      background-color: var(--ElectronBlue);
      font-size: 24px;
      font-weight: 700;
      text-decoration: none;
      cursor: pointer;
      user-select: none;

      &:not(:first-child) {
        margin-left: 16px;
      }

      &:hover {
        opacity: 0.9;
      }
      &:active {
        opacity: 0.7;
      }

      &.disabled {
        opacity: 0.3;
        cursor: inherit;
      }
    }
  }

  .loading-overlay {
    z-index: 10;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.7);
  }

  .loader {
    color: var(--SuperLightGray);
  }
}

@media screen and (max-width: 680px) {
  .add-review {
    width: 100vw;
    display: flex;
    flex-direction: column;
    overflow-x: hidden;

    .hero {
      font-size: 22px;
      text-align: center;

      .hero-title {
        margin-bottom: 20px;
      }

      .cta-row .cta-button {
        height: 36px;
        min-width: 0px;
        font-size: 16px;
      }
    }

    .content-wrapper {
      padding: 20px 0;

      .content-title {
        width: 90%;
        margin-bottom: 20px;
      }

      .info {
        width: 90%;

        .info-row {
          margin-top: 0px;
          display: block;

          &:first-child {
            margin-top: 0px;

            .form-input-wrapper:first-child .form-input-label {
              margin-top: 0px;
            }
          }

          .form-input-label {
            margin-top: 20px;
          }
        }
      }

      .cta-button {
        height: 48px;
        width: 100%;
        min-width: 0px;
        font-size: 20px;
      }
    }
  }
}
