@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@300;500;600;800;900&display=swap");

html {
  --LightGreenishBlue: rgb(85, 239, 196);
  --FadedPoster: rgb(129, 236, 236);
  --GreenDarnerTail: rgb(116, 185, 255);
  --ShyMoment: rgb(162, 155, 254);
  --CityLights: rgb(223, 230, 233);
  --MintLeaf: rgb(0, 184, 148);
  --RobinsEggBlue: rgb(0, 206, 201);
  --ElectronBlue: rgb(9, 132, 227);
  --ExodusFruit: rgb(108, 92, 231);
  --SoothingBreeze: rgb(178, 190, 195);
  --SourLemon: rgb(255, 234, 167);
  --FirstDate: rgb(250, 177, 160);
  --PinkGlamour: rgb(255, 118, 117);
  --Pico-8Pink: rgb(253, 121, 168);
  --AmericanRiver: rgb(99, 110, 114);
  --BrightYarrow: rgb(253, 203, 110);
  --Orangeville: rgb(225, 112, 85);
  --Chi-Gong: rgb(214, 48, 49);
  --PrunusAvium: rgb(232, 67, 147);
  --DraculaOrchid: rgb(45, 52, 54);

  --SuperLightGray: rgb(233, 240, 240);

  font-family: Montserrat;
}
