.agent-result {
  min-height: 160px;
  width: 100%;
  padding: 24px;
  display: flex;
  align-items: center;
  background-color: var(--SuperLightGray);
  border-radius: 12px;
  cursor: pointer;

  &:not(:first-child) {
    margin-top: 30px;
  }

  &:hover {
    opacity: 0.925;
  }
  &:active {
    opacity: 0.8;
  }

  .profile-image-placeholder {
    height: 120px;
    width: 120px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    background-color: var(--SoothingBreeze);

    .user-icon {
      height: 72px;
      width: 72px;
      stroke: white;
      fill: none;
      stroke-width: 1.5px;
      stroke-linecap: round;
      stroke-linejoin: round;
    }
  }

  .agent-info-wrapper {
    flex: 1;
    height: 100%;
    padding: 0px 20px 0px 40px;
    display: flex;
    flex-direction: column;

    .agent-summary-info {
      display: flex;

      .main-details {

        .name {
          font-size: 24px;
          font-weight: 500;
        }

        .state {
          margin-bottom: 20px;
          font-size: 14px;

          .pin-icon {
            height: 12px;
            width: 12px;
            margin-right: 8px;
            stroke: var(--AmericanRiver);
            fill: none;
            stroke-width: 2px;
            stroke-linecap: round;
            stroke-linejoin: round;
          }
        }
      }

      .rating-wrapper {
        margin-right: 0px;
        margin-left: auto;
        margin-bottom: 10px;
        display: flex;
        flex-direction: column;

        .rating-value {
          display: flex;
          flex-direction: row;
          border-radius: 10px;
          margin-bottom: 2px;
          padding: 4px 8px;

          .rating-numerator {
            margin-right: 8px;
            font-family: sans-serif;
            font-weight: 900;
            font-size: 40px;
          }

          .rating-slash {
            top: 6px;
            margin-right: 6px;
            color: #777777;
            font-size: 18px;
            font-weight: 700;
            font-family: sans-serif;
            position: relative;
          }

          .rating-denominator {
            top: 6px;
            color: var(--SuperLightGray);
            font-size: 18px;
            font-weight: 700;
            font-family: sans-serif;
            position: relative;
          }
        }

        .rating-amount {
          text-align: center;
          font-size: 13px;
        }
      }

    }
    .reviews {
      flex: 1;
      font-size: 14px;
      font-style: italic;
    }
  }
}

@media screen and (max-width: 680px) {
  .agent-result {
    min-height: 80px;
    padding: 16px;
    align-items: flex-start;

    &:not(:first-child) {
      margin-top: 10px;
    }

    .profile-image-placeholder {
      height: 52px;
      width: 52px;

      .user-icon {
        height: 40px;
        width: 40px;
      }
    }

    .agent-info-wrapper {
      padding: 0px 16px;

      .agent-summary-info {
        display: flex;

        .main-details {
          .name {
            font-size: 16px;
          }
        }

        .rating-wrapper {

          .rating-value {

            .rating-numerator {
              font-size: 30px;
            }

            .rating-slash {
              top: 3px;
              font-size: 15px;
            }

            .rating-denominator {
              top: 3px;
              font-size: 15px;
            }
          }
        }
      }
    }
    .reviews {
      font-size: 12px !important;
    }
  }
}
#grey-rating {
  background-color: --var(SoothingBreeze);
}
#red-rating {
  background-color: #e0501b;
}
#orange-rating {
  background-color: #e38e1c;
}
#yellow-rating {
  background-color: #c7c611;
}
#yellow-green-rating {
  background-color: #7dd22d;
}
#green-rating {
  background-color: #06ba81;
}
