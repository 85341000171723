.search-input-wrapper {
  position: relative;
  flex: 1;
  height: 36px;
  max-width: 600px;
//   margin-left: 108px;
  font-size: 20px;

  .search-input {
    height: 36px;
    width: 100%;
    padding: 0px 24px 0px 48px;
    background-color: white;
    border-radius: 28px;
    font-size: 20px;
    color: var(--DraculaOrchid);
    outline: none;
    border: none;

    &::placeholder {
      color: var(--DraculaOrchid);
      font-family: Montserrat;
      font-weight: 300;
      opacity: 0.3;
    }
  }

  .search-icon {
    position: absolute;
    top: 50%;
    left: 24px;
    transform: translate(-50%, -50%);
    height: 20px;
    width: 20px;
    stroke: var(--DraculaOrchid);
    fill: none;
    stroke-width: 2px;
    stroke-linecap: round;
    stroke-linejoin: round;
  }

  .clear-search-icon {
    position: absolute;
    top: 50%;
    right: 4px;
    transform: translate(-50%, -50%);
    height: 20px;
    width: 20px;
    stroke-width: 2px;
    stroke-linecap: round;
    stroke-linejoin: round;
  }
}

@media screen and (max-width: 680px) {
  .header .search-input-wrapper {
    position: relative;
    flex: 0;
    height: 36px;
    width: 80%;
    margin-left: 0px;
    font-size: 20px;

    .search-input {
      height: 36px;
      width: 100%;
      // padding: 0px 24px 0px 48px;
      background-color: white;
      border-radius: 28px;
      font-size: 20px;
      color: var(--DraculaOrchid);
      outline: none;
      border: none;

      &::placeholder {
        color: var(--DraculaOrchid);
        font-family: Montserrat;
        font-weight: 300;
        opacity: 0.3;
      }
    }

    .search-icon {
      position: absolute;
      top: 50%;
      left: 24px;
      transform: translate(-50%, -50%);
      height: 20px;
      width: 20px;
      stroke: var(--DraculaOrchid);
      fill: none;
      stroke-width: 2px;
      stroke-linecap: round;
      stroke-linejoin: round;
    }
  }
}
